.field{
    width: 40%;
    height: 36px;
    border-radius: 10px;
    border: 1px solid #282828;
    position: relative;
    background-color: rgba(250, 250, 250, 0.3);
    border-style: solid;
    transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out;
    @include respond(tab-port){
        width: 100%;
    }
    input {
        width: 100%;
        height: 36px;
        position: relative;
        padding: 0px 16px;
        border: none;
        border-radius: 4px;
        font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        background-color: transparent;
        color: #282828;
        outline: none;
        box-shadow: 0px 4px 20px 0px transparent;
        transition: 0.3s background-color ease-in-out, 0.3s box-shadow ease-in-out, 0.1s padding ease-in-out;
        -webkit-appearance: none;
    }
}

.cursorPointer {
    cursor: pointer;
}
.main-field{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}