.btn{
    border-radius: 18px;
    border: transparent;
    background-color: $color-primary;
    color: #fff;
    box-shadow: 0 6px 10px rgb(0 0 0 / 25%);
    padding: 5px 12px;
    cursor: pointer;

    &-md {
        display: flex;
        border-radius: 18px;
        border: transparent;
        background-color: $color-primary;
        color: #fff;
        box-shadow: 0 6px 10px rgb(0 0 0 / 25%);
        padding: 5px 30px;
        font-size: 15px;
        cursor: pointer;
    }

    &_icon{
        font-size: 17px;
        position: relative;
        top: 3px;
        margin-right: 5px;
        &_md {
            // color: #fff;
            height: 17px;
        }
    }
}
