* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
.loader {
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
}

.check {
    width: 30px;
    height: 30px;
}
.menuBtnRunning, .menuBtn {
    padding-top: 15px;
    text-decoration: underline;
}

.dlBtn {
    cursor: pointer;
}

.exclamation {
    width: 30px;
    height: 30px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
a,
.card__exit,
.card__icon {
    position: relative;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.9);
}

p,
.card__exit,
.card__icon {
    position: relative;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.9);
}

.card {
    margin: 15px;
    padding: 10px 17px;
    width: 170px;
    display: grid;
    grid-template-rows: 5px 20px 0fr 70px;
    border-radius: 10px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    transition: all 0.5s;
    text-align: center;
    font-size: 14px;

    &-container{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include respond(tab-port){
            justify-content: center;
        }

    }

    &__exit {
        grid-row: 1/2;
        justify-self: end;
    }

    &__icon {
        grid-row: 1/3;
        font-size: 30px;
    }

    &__title {
        grid-row: 3/4;
        font-weight: 400;
        color: #ffffff;
    }
    &__title_black {
        grid-row: 3/4;
        font-weight: 400;
        color: #000000;
    }

    &__apply {
        grid-row: 4/5;
        align-self: center;
    }

    &__apply_white {
        grid-row: 4/5;
        align-self: center;
    }

    &-buttons{
        grid-row: 5/5;
        display:flex;
        justify-content: space-between
    }
    &-buttons2{
        grid-row: 5/5;
        display:flex;
        justify-content: flex-end
    }
    &-ok {
        background: radial-gradient($color-primary, $color-primary);
    }

    &-pending {
        background: radial-gradient($color-white, $color-white);
    }
    &-failed {
        background: #df4759;
    }
    &__container-arrow{
        display: flex;
        position: relative;
        align-items: center;
        @include respond(tab-land){
            display: none;
        }
        &-body{
            width: 20px;
            height: 20px;
            background-color: $color-primary;
            &-2{
                width: 20px;
                height: 20px;
                background-color: $color-secondary;
            }
        }
        &-head{
            width: 0;
            height: 0;
            border-top: 20px solid transparent;
            border-left: 20px solid $color-primary;
            border-bottom: 20px solid transparent;
            &-2{
                width: 0;
                height: 0;
                border-top: 20px solid transparent;
                border-left: 20px solid $color-secondary;
                border-bottom: 20px solid transparent;
            }
        }
    }
}
@media only screen and (max-width: 1839px) {

    .card {
        &__container-arrow{
            display: none;
        }
    }
}

@media only screen and (max-width: 1440px) {
    .mb-middle, .mb-bottom {
        margin: auto;
    }
    .card {
        margin: 10px;
        padding: 15px;
        width: 150px;
        display:grid;
        grid-template-rows: 5px 25px 1fr 40px;
        border-radius: 10px;
        box-shadow: 0px 6px 10px rgb(0 0 0 / 25%);
        transition: all 0.5s;
        font-size: 12px;
        &__container-arrow{
            display: none;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .mb-middle, .mb-bottom {
        margin: auto;
    }
    .card {
        margin: 20px;
        padding: 20px;
        width: 200px;
        display: grid;
        grid-template-rows: 5px 35px 1fr 40px;
        border-radius: 10px;
        box-shadow: 0px 6px 10px rgb(0 0 0 / 25%);
        transition: all 0.5s;
        font-size: 14px;
        &__container-arrow{
            display: none;
        }
    }
    .card-container{
        margin: 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

@media only screen and (max-width: 768px) {
    .mb-middle, .mb-bottom {
        margin: auto;
    }
    .card {
        margin: 10px;
        padding: 10px;
        width: 150px;
        display: grid;
        grid-template-rows: 5px 25px 1fr 40px;
        border-radius: 10px;
        box-shadow: 0px 6px 10px rgb(0 0 0 / 25%);
        transition: all 0.5s;
        font-size: small;
        &__container-arrow{
            display: none;
        }
    }
}




.mb-middle {
    margin: 0 auto;
}
