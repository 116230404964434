.tbl-container{
    width: 100%;
    height: calc(100vh - 200px);
    overflow-x: auto;
}

.step-1-table {
    border-collapse: collapse;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0px 6px 10px rgb(0 0 0 / 25%);
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    overflow-y: scroll;
}

.step-1-table thead tr {
    background-color: $color-primary;
    color: $color-white;
    text-align: left;
}

.step-1-table th,
.step-1-table td {
    padding: 10px;
}

.step-1-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.step-1-table tbody tr:nth-of-type(odd) {
    background-color: #de9eff;
}

.step-1-table tbody tr:nth-of-type(even) {
    background-color: #e5c3f7;
}

.step-1-table tbody tr:last-of-type {
    border-bottom: 2px solid $color-primary;
}
.pagination{
    display: flex;
    justify-content: center;
    margin-top: 30px;
    list-style: none;
    .page-item{
        button{
            padding: 8px 12px;
            border: 1px solid #dee2e6;
            background-color: #fff; 
            cursor: pointer;
            &:hover{
                color: #007bff;
            }
        }
    }
}

.styled-table {
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    box-shadow: 0px 6px 10px rgb(0 0 0 / 25%);
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-top: 20px;
  
  }
  
  .styled-table thead tr {
    background-color: $color-primary;
    color: $color-white;
    text-align: left;
  }
  
  .styled-table th,
  .styled-table td {
    padding: 12px 15px;
  }
  
  .styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
  }
  
  .styled-table tbody tr:nth-of-type(odd) {
    background-color: #de9eff;
  }
  
  .styled-table tbody tr:nth-of-type(even) {
    background-color: #e5c3f7;
  }
  
  .styled-table tbody tr:last-of-type {
    border-bottom: 2px solid $color-primary;
  }
  
  table, td, th {
    border: 2px solid rgb(255, 255, 255);
  }
  
  
  .blank {
    width: 150px;
  }
  
  @media only screen and (max-width: 1200px) {
    .mb-bottom {
        margin: auto;
    }
  }
  
  @media only screen and (max-width: 1075px){
    .mb-bottom {
      margin-left: -105px;
    } 
  }
  
  @media only screen and (max-width: 960px){
    .mb-bottom {
      margin-left: -240px;
  }
  }
  
  @media only screen and (max-width: 890px){
    .mb-bottom {
      margin-left: -315px;
    }
  }
  
  @media only screen and (max-width: 1199px){
    .styled-table {
      border-collapse: collapse;
      margin: 25px 0;
      font-size: 0.9em;
      font-family: sans-serif;
      box-shadow: 0px 6px 10px rgb(0 0 0 / 25%);
      margin-left: auto;
      margin-right: auto;
      width: 80%;
      margin-top: 0px;
    }
    .mb-bottom {
      height: 335px;
    }
  }
  
  @media only screen and (max-width: 1075px){
    .styled-table {
      border-collapse: collapse;
      margin: 25px 0;
      font-size: 0.9em;
      font-family: sans-serif;
      box-shadow: 0px 6px 10px rgb(0 0 0 / 25%);
      margin-left: auto;
      margin-right: auto;
      width: 66%;
      margin-top: -5px;
    }
  }
  
  
  