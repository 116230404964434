.stats{
    padding: 50px 150px;
    &_container{
        display: flex;
        justify-content: space-between;
        flex-flow: column;
    }
    &_headerbox{
        margin-bottom: 20px;
    }
    
    &_headerbox, &_tblbox{
        display: flex;
        justify-content: space-between;
    }
    @include respond(tab-port){
        padding: 10px 20px;
        &_headerbox{
            flex-flow: column;
            align-items: center;
            div{
                margin-bottom: 10px;
            }
        }
        &_tblbox{
            flex-flow: column;
            align-items: center;
        }
    }
    @include respond(phone){
        padding: 10px 20px;
        &_headerbox{
            flex-flow: column;
            align-items: center;
            div{
                margin-bottom: 10px;
            }
        }
        &_tblbox{
            flex-flow: column;
        }
    }
}

.stats_tblbox{
    table, td, th {
        border: 2px solid rgba(255, 255, 255, 0);
    }
}