.pagination a.active {
  background-color: #4caf50;
  color: white;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.styled-table-btn {
  border-collapse: collapse;
  font-size: 0.9em;
  font-family: sans-serif;
  box-shadow: 0px 6px 10px rgb(0 0 0 / 25%);
  margin-left: auto;
  margin-right: auto;
  overflow-y: scroll;
  width: 100%;
}

.styled-table-btn thead tr {
  background-color: $color-primary;
  color: #ffffff;
  text-align: left;
}

.styled-table-btn th,
.styled-table-btn td {
  padding: 15px 15px;
}

.styled-table-btn tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table-btn tbody tr:nth-of-type(odd) {
  background-color: #de9eff;
}

.styled-table-btn tbody tr:nth-of-type(even) {
  background-color: #e5c3f7;
}

.styled-table-btn tbody tr:last-of-type {
  border-bottom: 2px solid $color-primary;
}

table,
td,
th {
  border: 2px solid rgb(255, 255, 255);
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  z-index: 9999;
}

.modal.show {
  opacity: 1;
  pointer-events: visible;
}

.modal-content {
  width: 1600px;
  height: 855px;
  background-color: #ffffff;
  transform: translateY(-200px);
  transition: all 0.3s ease-in-out;
  padding: 15px;
}

.modal.show .modal-content {
  transform: translateY(0);
}

.modal-title {
  margin: 0;
}

.modal-header {
  width: 1600px;
  display: flex;
  justify-content: space-between;
}

.modal-body {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-top: 20px;
  padding-bottom: 20px;
}

.modalNewItem {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  z-index: 9999;
  flex-direction: row;
  &-item {
    // order: 1
    align-content: center;
  }
}

.modalNewItem.show {
  opacity: 1;
  pointer-events: visible;
}

.modalNewItem-content {
  width: 760px;
  height: 300px;
  background-color: #ffffff;
  transform: translateY(-200px);
  transition: all 0.3s ease-in-out;
  padding: 15px;
  border-radius: 10px;
}

.modalNewItem.show .modalNewItem-content {
  transform: translateY(0);
}

.modalNewItem-title {
  margin: 0;
}

.modalNewItem-header {
  width: 1600px;
  display: flex;
  justify-content: space-between;
}

.modalNewItem-body {
  margin: 10px;
  border: 3px solid #333;
}

.flexbox-container {
  height: 245px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.flexbox-container-upload {
  height: 270px;
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.flexbox-item {
  margin: 10px;
  // border: 3px solid #333;
  // display: flex;
  flex-direction:column ;
  flex-wrap: wrap;
  align-content: flex-end;
}

.flexbox-item-upload {
  width: 600px;
  margin: 10px;
  // border: 3px solid #333;
  &-file {
    display: flex;
    width: 600px;
    margin: 10px;
    justify-content: center;
    margin-bottom: 33px;
    // border: 3px solid #333;
  }
  &-submit {
    display: flex;
    width: 600px;
    margin: 10px;
    // border: 3px solid #333;
    justify-content: center;
  }
}

.flexbox-item-icon {
  min-height: 100px;
  width: 150px
  
}

.flexbox-item-icon-placeholder {
  min-height: 100px;
}


.flexbox-item-text {
  min-height: 40px;
}

.flexbox-item-h2 {
  height: 1.5em;
  position: relative
}

.flexbox-item-h3 {
  height: 1em;
  position: relative
}

.close {
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 6px;
  width: 26px;
  height: 26px;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 10px;
  content: ' ';
  height: 15px;
  width: 2px;
  background-color: #333;
  top: 4px;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
.btnClose-bottom {
  float: right;
  margin-top: 7px;
  margin-right: 32px;
}

@media only screen and (max-width: 1630px) {
  .modal-content {
    width: 1400px;
    height: 855px;
    background-color: #ffffff;
    transform: translateY(-200px);
    transition: all 0.3s ease-in-out;
  }
}

@media only screen and (max-width: 1430px) {
  .modal-content {
    width: 1200px;
    height: 855px;
    background-color: #ffffff;
    transform: translateY(-200px);
    transition: all 0.3s ease-in-out;
  }
}

@media only screen and (max-width: 1225px) {
  .modal-content {
    width: 1000px;
    height: 855px;
    background-color: #ffffff;
    transform: translateY(-200px);
    transition: all 0.3s ease-in-out;
  }
}

.btnViewDetails {
  margin-left: 7px;
  background-color: #cb6efb;
  color: white;
  padding: 8px 10px;
  text-align: center;
  border-radius: 20px;
  text-decoration: none;
}

.modal-metrics {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
  z-index: 9999;
}
.modal-header-metrics {
  position: relative;
  display: flex;
  padding: 20px;
}
.modal-content-metrics {
  width: 1600px;
  height: 800px;
  background-color: #ffffff;
  transition: all 0.3s ease-in-out;
}
.modal-metrics.show {
  opacity: 1;
  pointer-events: visible;
}
.modal-content-metrics {
  width: 1600px;
  height: 855px;
  background-color: #ffffff;
  transform: translateY(-200px);
  transition: all 0.3s ease-in-out;
}
.modal-metrics.show .modal-content-metrics {
  transform: translateY(0);
}
.close-met {
  position: absolute;
  right: 5px;
  top: 10px;
  width: 26px;
  height: 26px;
  opacity: 0.3;
}
.close-met:hover {
  opacity: 1;
}
.close-met:before,
.close-met:after {
  position: absolute;
  left: 10px;
  content: ' ';
  height: 15px;
  width: 2px;
  background-color: #333;
  top: 4px;
}
.close-met:before {
  transform: rotate(45deg);
}
.close-met:after {
  transform: rotate(-45deg);
}
.city {
  float: right;
  margin-right: 127px;
  margin-top: 5px;
}
.ncais {
  float: left;
  margin-left: 110px;
}
