.cont-h{
    padding: 2px;
  }
  .header p {
    margin-right: 40px;
    margin-top: 12px !important;
    font-family: sans-serif;
    color: white;
  }
  .header h3, .header p{
    margin-left: 15px;
    display: block;
    margin: 2px 0 0 0;
    margin-left: 30px;
  }
  .header{
    position: relative;
    height: 50px;
    width: 100% ;
    margin-bottom: 0px;
    background: $color-primary;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);
    z-index: 9999;
  }

  .vl {
    border-left: 2px solid white;
    height: 38px;
    float: right;
    margin-right: 45px;
    margin-top: -8px;
  }

  .h-user {
    zoom: 180%;
    float: right;
    margin-right: -21px;
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    color: white;
}