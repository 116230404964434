.footer {
    position: relative;
    height: 50px;
    // box-shadow: 0px -6px 10px rgba(0, 0, 0, 0.25);
    background-color: white;
    @include respond(tab-port){
      margin-top: 25px;
    }
  }
  
  .cont-f {
    display: flex;
    float: left;
    margin-top: 10px;
    @include respond(tab-port){
      flex-flow: column;
      align-items: center;
      float: none;
    }
  }
  
  .f-mes {
    margin-top: 9px;
    font-size: 10px;
    margin-left: 10px;
    color: black;
  }
  
  .f-tou, .f-ps, .f-cs {
      margin-top: 11px;
      font-size: 10px;
      margin-left: 10px;
      color: black;
  }
  .f-acn-logo-btm {
    width: 80px;
    height: 22px;
    margin-left: 30px;
    
    @include respond(tab-port){
      margin-left: 0;
    }
  }
  
  .body-bottom {
    margin-top: 20px;
  }
  
  .mb-bottom {
    height: 290px;
  }
  
  