.body-middle {
    display: flex;
    margin: 0 auto;
    padding: 40px 100px;
    @include respond(tab-land){
        margin: 0;
        padding: 0;
    }
}
.body-bottom{
    width: 100%;
    padding: 10px 150px;
    @include respond(tab-port){
        padding: 10px 20px;
    }
    &-header{
        font-weight: Bold;
        text-decoration: underline;
        margin-bottom: 10px;
        @include respond(tab-port){
            text-align: center;
        }
    }
    &-subheader{
        font-weight: normal;
        padding-left: 10px;
        margin-bottom: 25px;
    }

    &_input-container{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 20px;
    }
}

::-webkit-scrollbar {
    width: 8px;
}
 
::-webkit-scrollbar-track {
    background-color: #e4e4e4;
    border-radius: 100px;
}
 
::-webkit-scrollbar-thumb {
    background-color: darkgray;
    border-radius: 100px;
}

.login-container{
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-button{
    font-size: 16px;
    padding: 20px;
    height: 55px;
    background: #9718e8;
    color: #fff;
    font-weight: 700;
    box-shadow: none;
    border: none;
    border-radius: 20px;
}